import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOInterface {
  description?: string;
  lang?: string;
  meta?: any;
  title: string;
}

interface QueryResponse {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export const SEO = ({ description, lang, meta, title }: SEOInterface) => {
  return (
    <StaticQuery
      query={query}
      render={(data: QueryResponse) => {
        const metaDescription = description || data.site.siteMetadata.description;

        return (
          <Helmet
            htmlAttributes={{ lang: lang ? lang : 'pl' }}
            titleTemplate={`${data.site.siteMetadata.title} - %s`}
            title={title}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
            ].concat(meta || [])}
          >
            <script>
              {`
                var head = document.getElementsByTagName( 'head' )[0];
                var insertBefore = head.insertBefore;
                head.insertBefore = function( newElement, referenceElement ) {
                  if( newElement.href && newElement.href.indexOf(
                    'https://fonts.googleapis.com/css?family=Roboto'
                    ) === 0 ) {
                    return;
                  }
                  insertBefore.call( head, newElement, referenceElement );
                };
              `}
            </script>
          </Helmet>
        );
      }}
    />
  );
};
